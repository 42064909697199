<template>
  <div>
    <v-container>
      <v-breadcrumbs
        :items="breadcrumbs"
        class="pa-0 pb-2"
        divider=">"
      ></v-breadcrumbs>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h2>Categories</h2>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Categories"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-btn
              class="mr-2"
              depressed
              light
              :to="{ name: 'module-eow-products' }"
            >
              <v-icon small left>mdi-arrow-left</v-icon> Back to Products
            </v-btn>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  color="accent"
                  class="mr-2"
                  v-on="on"
                  @click="$refs.categoryForm.openForm()"
                >
                  <v-icon small>mdi-plus</v-icon>
                </v-btn>
              </template>
              <span>Add New Category</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="parentCategories"
        :items-per-page="-1"
        show-expand
        :expanded="parentCategories"
      >
        <template v-slot:item.is_parent="{ item }">
          <v-icon small v-if="item.is_parent">mdi-check</v-icon>
        </template>
        <template v-slot:item.is_quick_link="{ item }">
          <v-icon small v-if="item.is_quick_link">mdi-check</v-icon>
        </template>

        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                v-on="on"
                class="mr-2"
                :to="{
                  name: 'module-eow-products-categories-individual',
                  params: { categoryId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View Category</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.categoryForm.openForm(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDeleteCategory(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Delete</span>
          </v-tooltip>
        </template>

        <template v-slot:expanded-item="{ headers, item }">
          <td
            :colspan="headers.length"
            class="pl-5 pr-0"
            v-if="item.children.length > 0"
          >
            <v-data-table
              :headers="tableHeadersChild"
              :items="item.children"
              v-sortable-data-table="{ parentId: item.id }"
              @sorted="saveOrderChild"
              class="my-awesome-table"
              :disable-pagination="true"
              :hide-default-footer="true"
              :hide-default-header="true"
            >
              <template v-slot:item.sort="{ item }">
                <v-icon small class="page__grab-icon">mdi-arrow-all</v-icon>
              </template>
              <template v-slot:item.is_parent="{ item }">
                <v-icon small v-if="item.is_parent">mdi-check</v-icon>
              </template>
              <template v-slot:item.is_quick_link="{ item }">
                <v-icon small v-if="item.is_quick_link">mdi-check</v-icon>
              </template>

              <template #item.empty="{ item }"></template>

              <template v-slot:item.actions="{ item }">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="green lighten-4 green--text"
                      v-on="on"
                      class="mr-2"
                      :to="{
                        name: 'module-eow-products-categories-individual',
                        params: { categoryId: item.id },
                      }"
                    >
                      <v-icon x-small>mdi-eye</v-icon>
                    </v-btn>
                  </template>
                  <span>View Category</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="blue lighten-4 blue--text"
                      v-on="on"
                      class="mr-2"
                      @click="$refs.categoryForm.openForm(item)"
                    >
                      <v-icon x-small>mdi-pencil</v-icon>
                    </v-btn>
                  </template>
                  <span>Edit</span>
                </v-tooltip>
                <v-tooltip bottom>
                  <template v-slot:activator="{ on }">
                    <v-btn
                      x-small
                      depressed
                      color="red lighten-4 red--text"
                      v-on="on"
                      @click="openDeleteCategory(item, item.parents[0].id)"
                    >
                      <v-icon x-small>mdi-archive</v-icon>
                    </v-btn>
                  </template>
                  <span>Delete</span>
                </v-tooltip>
              </template>
            </v-data-table>
          </td>
        </template>

        <template #item.data-table-expand="{ item, expand, isExpanded }">
          <td v-if="item.children.length > 0" class="text-start" width="48px">
            <v-btn
              icon
              @click="expand(!isExpanded)"
              class="v-data-table__expand-icon"
              :class="{ 'v-data-table__expand-icon--active': isExpanded }"
            >
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </td>
        </template>
      </v-data-table>
    </v-container>
    <CategoryForm ref="categoryForm" />
    <v-dialog v-model="deleteCategory.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Delete Category</v-card-title>
        <v-card-text
          >Are you sure you wish to delete
          {{ deleteCategory.category.name }}?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDeleteCategory"
            >No, cancel</v-btn
          >
          <v-btn
            color="success"
            depressed
            :loading="deleteCategory.loading"
            @click="saveDeleteCategory"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import Sortable from "sortablejs";
import CategoryForm from "./components/CategoryForm";

export default {
  components: {
    CategoryForm,
  },

  data() {
    return {
      searchTerm: "",
      breadcrumbs: [
        {
          text: "eow",
          disabled: false,
          to: { name: "app-dashboard", params: { id: this.$route.params.id } },
        },
        {
          text: "Products",
          disabled: false,
          exact: true,
          to: {
            name: "module-eow-products",
          },
        },
        {
          text: "Categories",
          disabled: true,
        },
      ],
      tableHeaders: [
        { text: "", value: "data-table-expand", width: "5%" },
        { text: "Name", value: "name", width: "40%" },
        { text: "Is A Parent?", value: "is_parent", width: "10%" },
        { text: "Main Nav Item", value: "is_quick_link", width: "15%" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "right",
          width: "20%",
        },
      ],
      tableHeadersChild: [
        { text: "", value: "sort", width: "5%" },
        { text: "Name", value: "name", align: "left", width: "40%" },
        { text: "Is A Parent?", value: "is_parent" },
        { text: "Main Nav Item", value: "is_quick_link" },
        {
          text: "Actions",
          value: "actions",
          sortable: false,
          align: "right",
          width: "300",
        },
      ],

      deleteCategory: {
        dialog: false,
        category: {},
        parent_id: null,
        loading: false,
      },
    };
  },

  directives: {
    sortableDataTable: {
      bind(el, binding, vnode) {
        const options = {
          animation: 150,
          onUpdate: function (event) {
            event.parentId = binding.value.parentId;

            vnode.child.$emit("sorted", event);
          },
        };
        Sortable.create(el.getElementsByTagName("tbody")[0], options);
      },
    },
  },

  computed: {
    parentCategories() {
      return this.$store.getters["eow/productsStore/categories"].filter((c) => {
        return c.is_parent === true;
      });
    },

    categories() {
      let categories = this.$store.getters["eow/productsStore/categories"];

      if (this.searchTerm !== "") {
        categories = categories.filter((c) => {
          const name = c.name.toLowerCase();
          return name.includes(this.searchTerm.toLowerCase());
        });
      }

      return categories;
    },
  },

  methods: {
    saveOrderChild(event) {
      const index = this.categories.findIndex((p) => p.id === event.parentId);

      const movedItem = this.categories[index].children.splice(
        event.oldIndex,
        1
      )[0];
      this.categories[index].children.splice(event.newIndex, 0, movedItem);

      const displayOrder = this.categories[index].children.map((p) => {
        return p.id;
      });
      const appId = this.$route.params.id;
      const parentId = event.parentId;

      this.$store.commit("showHideLoader", true);
      this.$store
        .dispatch("eow/productsStore/updateCategoryDisplayOrder", {
          appId,
          parentId,
          displayOrder,
        })
        .then(() => this.$store.commit("showHideLoader", false))
        .catch(() => this.$store.commit("showHideLoader", false));
    },

    openDeleteCategory(category, parentId = null) {
      this.deleteCategory.category = category;
      this.deleteCategory.parent_id = parentId;
      this.deleteCategory.dialog = true;
    },

    resetDeleteCategory() {
      this.deleteCategory.dialog = false;
      this.deleteCategory.category = {};
      this.deleteCategory.parent = {};
      this.deleteCategory.loading = false;
    },

    saveDeleteCategory() {
      this.deleteCategory.loading = true;

      this.$store
        .dispatch("eow/productsStore/deleteCategory", {
          appId: this.$route.params.id,
          categoryId: this.deleteCategory.category.id,
          parentId: this.deleteCategory.parent_id,
        })
        .then(() => {
          this.resetDeleteCategory();
        })
        .catch(() => {
          this.deleteCategory.loading = false;
        });
    },
  },
};
</script>
<style>
.v-data-table__expanded.v-data-table__expanded__content {
  box-shadow: none !important;
}
.my-awesome-table tr td:first-child {
  width: 10px;
}
</style>
